<template>
  <div class="contribution-card-list">
    <van-sticky>
      <van-config-provider>
        <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
      </van-config-provider>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <div class="order-list">
        <van-list
          v-if="dataList.length >0"
          v-model:loading="loading"
          :finished="finished"
          :finished-text="$t('common.noMore')"
          @load="loadData"
        >
          <div
            :class="`card-block ${item.showStatus == 2 ? 'soldout' : ''}`"
            :style="`background: url(${item.imgUrl}) 0 0/100% auto no-repeat;`"
            v-for="item in dataList"
            :key="item.id"
            @click="goToDetail(item)"
          >
            <span class="card-name">{{item.name}}</span>
            <div class="detail-box">
              <div class="detail-col col1">
                <p class="detail-label">{{item.deposit}} {{$AppData.config.appCoin}}</p>
                <p class="detail-value">{{$t('field.needDeposit')}}</p>
              </div>
              <div class="divider"></div>
              <div class="detail-col col2">
                <p class="detail-label">{{item.useDay}}</p>
                <p class="detail-value">{{$tc('field.useDay',item.useDay)}}</p>
              </div>
              <div class="divider"></div>
              <div class="detail-col col3">
                <p class="detail-label">{{item.contribution}}</p>
                <p class="detail-value">{{$t('field.dayContribution')}}</p>
              </div>
            </div>
            <!-- <van-image v-if="item.showStatus == 1" class="stamp-img" src="/images/contribution/stamp.png" /> -->
          </div>
        </van-list>
        <div v-else>
          <van-empty :description="$t('common.noData')"></van-empty>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Toast } from 'vant';
import useClipboard from 'vue-clipboard3';
import i18n from '@/assets/lang/index';
import { queryCCardInCategory } from "@/api";

export default {
  setup() {
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    return {
      loading,
      finished,
      refreshing,
      dataList,
    };
  },
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      categoryId: this.$route.params.categoryId,
      current: 1,
    };
  },
  
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      queryCCardInCategory({
        id: this.categoryId,
        pageSizeStr: 10,
        pageNumberStr: this.current,
      }).then(res => {
        this.dataList = this.dataList.concat(res.data || []);
        this.current = res.pageNumber + 1;
        this.loading = false;
        if (res.totalNumber <= res.pageNumber * res.pageSize) {
          this.finished = true;
        }
      });
    },
    loadData() {
      if (this.refreshing) {
        this.dataList = [];
        this.current = 1;
        this.refreshing = false;
      }
      this.fetchData();
    },
    refreshData() {
      // 清空列表数据
      this.finished = false;
            
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    goToDetail(item) {
      localStorage.setItem('cardDetail', JSON.stringify(item));
      this.$router.push('/contribution/card/detail');
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.contribution-card-list {
  .order-list {
    padding: 14px;
  }
  .card-block {
    position: relative;
    min-height: 152px;
    padding: 10px;
    margin-bottom: 14px;
    border-radius: 4px;
    box-sizing: border-box;
    // .van-image,
    // .van-image img{
    //   display: block;
    //   width: 100%;
    //   min-height: 60px;
    //   border-radius: 9px;
    // }
    .card-name {
      display: inline-block;
      line-height: 27px;
      padding: 0 8px;
      font-size: 16px;
      font-weight: 500;
      margin: 5px 0 32px;
      color: #fff;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.16);
    }
    .detail-box {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 500;
      margin: 0 4px;
      padding: 12px 0;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.16);
      .detail-col {
        text-align: center;
        &.col1 { width: 104px; }
        &.col2 { width: 98px; }
        &.col3 { flex: 1; }
        .detail-label {
          min-height: 21px;
          font-size: 18px;
          margin-bottom: 8px;
        }
        .detail-value {
          font-size: 12px;
        }
      }
    }
    .divider {
      width: 1px;
      height: 28px;
      background: #fff;
    }
    &.soldout {
      .card-name, .detail-box { background: rgba(255, 255, 255, 0.25); }
    }
    .stamp-img {
      position: absolute;
      top: 12px;
      right: 24px;
      width: 51px;
    }
  }
}
</style>
